<template>
  <div class="reject-reasons">
    <ConfigurationHeaderContainer title="Exclusion Reasons for Project X"></ConfigurationHeaderContainer>
    <div class="form-entry-container">
      <div class="form-entry-label">Exclusion Reasons</div>
      <div class="form-entry-input">
        <div class="stages">
          <div class="stages-item">
            up<br>down
            <kendo-datasource
              :ref="'ExclusionReasonDataSource'"
              :data="ExclusionReasonDataSource.data">
            </kendo-datasource>
            <kendo-grid
              :id="'ExclusionReasonGrid'"
              :ref="'ExclusionReasonGrid'"
              :data-source-ref="'ExclusionReasonDataSource'"
              :scrollable="scrollable"
              :selectable="selectable"
              :columns="columns"
              :toolbar-tools="['moveUp', 'moveDown']"
              style="width: calc(100% - 145px);">
            </kendo-grid>
              <div>
              <a href="#">Add</a>
              <a href="#">Import</a>
              <a href="#">Edit</a>
              <a href="#">Delete</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FormEntryContainer title="Allow multiple Exclusion reasons" name="yesNo4" type="radioYesNo"></FormEntryContainer>
    <FormEntryContainer title="Mark different exclusion reasons as discrepancy" name="yesNo4" type="radioYesNo"></FormEntryContainer>
  </div>
</template>

<script>
import ConfigurationHeaderContainer from '@/containers/ConfigurationHeaderContainer'
import FormEntryContainer from '@/containers/FormEntryContainer'
import StageDataSource from '@/assets/data/Stage.json'
import ExclusionReasonDataSource from '@/assets/data/ExclusionReason.json'
import ExclusionReasonStageDataSource from '@/assets/data/ExclusionReasonStage.json'

export default {
  name: 'reject-reasons',
  components: {
    ConfigurationHeaderContainer,
    FormEntryContainer
  },
  data () {
    return {
      selectable: true,
      scrollable: true,
      ExclusionReasonDataSource: ExclusionReasonDataSource,
      columns: this.generateColumns()
    }
  },
  methods: {
    generateColumns: function () {
      var columnsObject = []
      columnsObject.push({ field: 'Hierarchy', title: 'Order By', width: '120px', headerAttributes: { class: 'grid-header-light' } })
      columnsObject.push({ field: 'ExclusionReasonName', title: 'Reason', width: '120px', headerAttributes: { class: 'grid-header-light' } })
      StageDataSource.data.forEach(function (value) {
        columnsObject.push({ field: 'StageIdentity_' + value.StageIdentity, title: value.StageName, template: '<input type="checkbox" id="mm2_#: ExclusionReasonIdentity #_' + value.StageIdentity + '">', headerAttributes: { class: 'grid-header-light' } })
      })
      return columnsObject
    }
  },
  mounted: function () {
    ExclusionReasonStageDataSource.data.forEach(function (value) {
      document.getElementById('mm2_' + value.ExclusionReasonIdentity + '_' + value.StageIdentity).setAttribute('checked', '')
    })
  }
}
</script>
